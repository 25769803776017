import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button, InputField, Text, TextArea } from '@/components/atomic/atoms';
import YupValidator from '@/lib/yup-validator';
import { contactHaflaForm } from '@/services/contactUs.service';

const ContactForm = ({ legalNameOfPartnerOrg, setLoading, setShowToast }) => {
  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm({
    resolver: YupValidator(
      yup.object().shape({
        message: yup
          .string()
          .trim()
          .min(
            4,
            'Please enter your concern/question in the message box to submit the request'
          )
          .required(
            'Please enter your concern/question in the message box to submit the request'
          )
      })
    )
  });

  const onSubmit = (formData) => {
    contactHaflaForm({ data: formData, setLoading, setShowToast });
  };

  return (
    <div
      className={`flex flex-col justify-center md:w-47.5 mx-auto px-5 md:px-8 py-5 md:py-9 bg-brand-gradient text-white rounded-b-3xl md:rounded-t-3xl`}
    >
      <Text
        {...{
          className: 'text-2xl md:text-2xl font-semibold',
          content: 'Lets get in touch'
        }}
      />
      <Text
        {...{
          className: 'text-sm md:text-lg font-medium mt-2',
          content: 'Hi there! How can we help you?'
        }}
      />
      <Text
        {...{
          className: 'text-sm md:text-lg font-medium',
          content: 'Drop us a message about your concerns.'
        }}
      />
      <div className='mt-6 flex flex-col gap-5'>
        <InputField
          {...{
            baseClass:
              'border-b bg-transparent w-full outline-none font-medium text-sm py-2',
            disabled: true,
            label: 'Name',
            labelClass: 'text-white text-sm font-medium',
            value: legalNameOfPartnerOrg
          }}
        />
        <div>
          <Text
            {...{ className: 'text-sm font-medium pb-0', content: 'Message' }}
          />
          <TextArea
            {...{
              baseClasses:
                'border-b w-full border-base-white bg-transparent placeholder:text-light-pink outline-none text-sm font-medium pb-1 mt-2',
              dbName: 'message',
              errors,
              errorMessageClassName: 'text-xs',
              placeholder: 'Type here',
              register: register('message')
            }}
          />
        </div>
        <Button
          {...{
            children: 'Submit',
            className:
              'bg-white text-brand py-3 text-center rounded-lg text-sm font-medium mt-4',
            onClick: handleSubmit(onSubmit)
          }}
        />
      </div>
    </div>
  );
};

export default ContactForm;
