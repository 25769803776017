import {
  getPartnerSummaryStatsAPI,
  parsePartnerSummaryStats
} from 'services/home.service';
import * as HttpService from 'services/http.service';
import { GET_PARTNER_USER_PROFILE_API_URL } from 'services/url.service';

const getUserProfileDataAPI = () =>
  HttpService.getWithAuth(GET_PARTNER_USER_PROFILE_API_URL());

const getUserProfileData = async ({ setPartnerDetails }) => {
  const { entity, message, status } = await getUserProfileDataAPI();
  if (status) {
    setPartnerDetails(entity);
  }
  return { message, status };
};

const getPartnerSummaryStats = async ({ setPartnerSummaryStats }) => {
  const { entity, status, message } = await getPartnerSummaryStatsAPI();
  if (status) {
    const summaryStats = parsePartnerSummaryStats({ entity });
    setPartnerSummaryStats(summaryStats);
  }
  return { message, status };
};

export const fetchProfileData = async ({
  setLoading,
  setPartnerDetails,
  setPartnerSummaryStats,
  setShowToast
}) => {
  setLoading(true);
  const toast = { message: null, show: true, successToast: true };
  try {
    const [
      {
        message: partnerSummaryStatsApiMessage,
        status: partnerSummaryStatsApiStatus
      },
      { message: userProfileDataApiMessage, status: userProfileDataApiStatus }
    ] = await Promise.all([
      getPartnerSummaryStats({ setPartnerSummaryStats }),
      getUserProfileData({ setPartnerDetails })
    ]);
    switch (true) {
      case !partnerSummaryStatsApiStatus:
        toast.message = partnerSummaryStatsApiMessage;
        break;
      case !userProfileDataApiStatus:
        toast.message = userProfileDataApiMessage;
        break;
      default:
        toast.message = null;
        break;
    }
    toast.successToast =
      partnerSummaryStatsApiStatus && userProfileDataApiStatus;
    toast.show = !toast.successToast;
  } catch (error) {
    toast.message = error.message || 'Failed to Fetch API';
    toast.successToast = false;
  } finally {
    setShowToast(toast);
    setLoading(false);
  }
};

export const getProfileData = ({
  address,
  coreCategories,
  emailId,
  mobileNumber,
  pocName
}) => ({
  address,
  emailId,
  eventTypes: coreCategories
    .map(({ name }) => name)
    .filter((value) => value)
    .join(', '),
  mobileNumber,
  pocName
});
