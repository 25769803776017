import { Fragment } from 'react';

import {
  ContactForm,
  HaflaContactDetails
} from '@/components/atomic/molecules';

const ContactUsMobileOrganism = ({
  legalNameOfPartnerOrg,
  setLoading,
  setShowToast
}) => (
  <Fragment>
    <ContactForm
      {...{
        legalNameOfPartnerOrg,
        setLoading,
        setShowToast
      }}
    />
    <HaflaContactDetails />
  </Fragment>
);

export default ContactUsMobileOrganism;
