import { useState } from 'react';

import { Text } from '@/components/atomic/atoms';
import { BidItemDetail, BidItemSummary } from '@/components/atomic/molecules';
import { BID_STATUS } from '@/config/bid';
import { mediaListMapperForBidItemDetailAPI } from '@/helpers/carousel';

const BidItemSummaryCard = ({ item, setOpenBidItemDetail, status }) => (
  <BidItemSummary
    {...{
      bidStatus: status,
      item,
      setOpenBidItemDetail
    }}
  />
);

const BidItemDetailCard = ({
  bidId,
  openBidItemDetail,
  partnerUser,
  referenceId,
  setBidDetails,
  setDeliveryChargeOffered,
  setDeliveryMode,
  setLoading,
  setOpenBidItemDetail,
  setShowToast,
  status
}) => (
  <BidItemDetail
    {...{
      bidId,
      bidStatus: status,
      openBidItemDetail,
      partnerUser,
      referenceId,
      setBidDetails,
      setDeliveryChargeOffered,
      setDeliveryMode,
      setLoading,
      setOpenBidItemDetail,
      setShowToast
    }}
  />
);

const BidItemList = ({
  bidId,
  items = [],
  openBidItemDetail,
  partnerUser,
  referenceId,
  setBidDetails,
  setDeliveryChargeOffered,
  setDeliveryMode,
  setLoading,
  setOpenBidItemDetail,
  setShowToast,
  status
}) =>
  items.map((item) => {
    const showSummary = item.id !== openBidItemDetail?.id;
    const BidItemCard = showSummary ? BidItemSummaryCard : BidItemDetailCard;
    return (
      <BidItemCard
        key={item.id}
        {...{
          bidId,
          item,
          openBidItemDetail,
          partnerUser,
          referenceId,
          setBidDetails,
          setDeliveryChargeOffered,
          setDeliveryMode,
          setLoading,
          setOpenBidItemDetail,
          setShowToast,
          status
        }}
      />
    );
  });

const BidItemListHeader = ({ status }) => {
  const bidStatusBasedClass =
    status === BID_STATUS.EXPIRED.value ? 'expired-bid' : '';
  return (
    <Text
      {...{
        content: 'Event Requirements',
        className: `text-base md:text-xl font-medium mx-6 md:mx-0 mb-6 ${bidStatusBasedClass}`
      }}
    />
  );
};

const BidItemListOrganism = ({
  bidId,
  items: itemsRaw,
  partnerUser,
  referenceId,
  setBidDetails,
  setDeliveryChargeOffered,
  setDeliveryMode,
  setLoading,
  setShowToast,
  status
}) => {
  const [openBidItemDetail, setOpenBidItemDetail] = useState(null);
  const items = itemsRaw.map(mediaListMapperForBidItemDetailAPI);
  return (
    <div className='mt-6'>
      <BidItemListHeader {...{ status }} />
      <BidItemList
        {...{
          bidId,
          items,
          openBidItemDetail,
          partnerUser,
          referenceId,
          setBidDetails,
          setDeliveryChargeOffered,
          setDeliveryMode,
          setLoading,
          setOpenBidItemDetail,
          setShowToast,
          status
        }}
      />
    </div>
  );
};

export default BidItemListOrganism;
