import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { components } from 'react-select';
import * as yup from 'yup';

import {
  Button,
  CloseIcon,
  InputField,
  Text,
  TextArea,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import PhoneCodeSelectFormInput from '@/components/general/PhoneCodeSelectFormInput';
import phoneCountryCodes from '@/config/phoneCountryCodes';
import isMobileNumberValid from '@/lib/validations/mobile-number';
import YupValidator from '@/lib/yup-validator';
import { guestContactUs } from '@/services/contactUs.service';
import { LOGIN_PAGE_JSX_MAP } from '@/services/login.service';

const ContactUs = ({ setActiveJSX, setLoading, setShowToast }) => {
  const [showOverlayer, setShowOverlayer] = useState(false);

  const yupResolver = YupValidator(
    yup.object().shape({
      mobileNumber: yup
        .string()
        .test('is-valid-number', 'Invalid phone number', (value, { parent }) =>
          isMobileNumberValid(`${parent.codeId.code}${value}`)
        )
        .required('Phone number is required'),
      name: yup
        .string()
        .trim()
        .min(4, 'Partner Name must be at least 4 characters.')
        .required('Partner name is required'),
      message: yup
        .string()
        .trim()
        .min(4, 'Message must be at least 4 characters.')
        .max(1024)
        .required(
          'Please enter your concern/question in the message box to submit the request'
        )
    })
  );

  const {
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      codeId: phoneCountryCodes[0],
      mobileNumber: ''
    }
  });
  const router = useRouter();

  const CustomOptionForPhoneNumberSelection = ({
    children,
    data: { code, name },
    ...props
  }) => (
    <components.Option {...props}>
      {code} {name}
    </components.Option>
  );

  return (
    <div className='bg-brand-gradient rounded-t-4xl md:w-43.5 fixed bottom-0 mx-auto left-0 right-0 text-white p-8'>
      {showOverlayer && (
        <div className='w-full h-screen fixed top-0 bottom-0 z-10 left-0'></div>
      )}
      <CloseIcon
        {...{
          icon: 'close-with-border-white.svg',
          onClick: () => setActiveJSX(LOGIN_PAGE_JSX_MAP.LOGIN_JSX)
        }}
      />
      <Text
        {...{
          className: 'text-3xl font-semibold',
          content: 'Let’s get in touch'
        }}
      />
      <TextKeyValuePair
        {...{
          className: 'text-sm md:text-lg font-medium mt-2 flex flex-col',
          label: 'Hi there! How can we help you?',
          labelClass: '',
          spaceTop: '',
          value: 'Drop us a message about your concerns.',
          valueClassName: ''
        }}
      />
      <div className='flex flex-col gap-6 mt-4'>
        <InputField
          {...{
            baseClass:
              'border-b w-full border-base-white bg-transparent placeholder:text-light-pink outline-none text-sm font-medium mt-2 pb-1',
            dbName: 'name',
            errorMessageClassName: 'text-xs flex gap-2 mt-2',
            errors,
            isShowErrorIcon: true,
            label: 'Name',
            labelClass: 'text-sm font-medium',
            placeholder: 'Name',
            register: register('name')
          }}
        />
        <div>
          <Text
            {...{ content: 'Phone Number', className: 'text-sm font-medium' }}
          />
          <div className='flex gap-4'>
            <PhoneCodeSelectFormInput
              {...{
                className: 'phone-code-select-login text-sm md:text-base',
                clearErrors,
                CustomOption: CustomOptionForPhoneNumberSelection,
                errors,
                getValues,
                inputFieldProps: {
                  baseClass:
                    'border-b w-full border-base-white bg-transparent placeholder:text-light-pink outline-none text-sm font-medium pb-1 mt-2',
                  dbName: 'mobileNumber',
                  errorMessageClassName: 'text-xs flex gap-2 mt-2',
                  errors,
                  isShowErrorIcon: true,
                  inputGroup: 'flex flex-1',
                  placeholder: 'Phone Number',
                  register: register('mobileNumber'),
                  type: 'number'
                },
                isShowErrorIcon: true,
                register,
                setValue,
                values: phoneCountryCodes,
                width: 'w-24'
              }}
            />
          </div>
        </div>
        <div>
          <Text
            {...{ content: 'Message', className: 'text-sm font-medium pb-0' }}
          />
          <TextArea
            {...{
              baseClasses:
                'border-b w-full border-base-white bg-transparent placeholder:text-light-pink outline-none text-sm font-medium pb-1 mt-2',
              dbName: 'message',
              errorMessageClassName: 'text-xs flex gap-2',
              errors,
              isShowErrorIcon: true,
              placeholder: 'Type here',
              register: register('message')
            }}
          />
        </div>

        <Button
          {...{
            children: 'Submit',
            className: 'bg-white text-brand font-medium rounded-md py-3',
            onClick: handleSubmit(({ message, name }) =>
              guestContactUs({
                getValues,
                message,
                name,
                router,
                setLoading,
                setShowOverlayer,
                setShowToast
              })
            )
          }}
        />
      </div>
    </div>
  );
};

export default ContactUs;
