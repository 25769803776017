import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { Button } from '@/components/atomic/atoms';
import { NavigationSideBar } from '@/components/atomic/molecules';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getInitiateWhatsappChatLink } from '@/services/home.service';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';
import { getAuth, isAuthenticated } from 'services/identity.service';

const haflaContactNumber = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER;

const NavigationMenu = ({ setShowNavigationSideBar }) => {
  const isAuthenticatedUser = isAuthenticated();

  return isAuthenticatedUser ? (
    <Image
      alt='hamburger'
      className='cursor-pointer'
      height={0}
      onClick={() => setShowNavigationSideBar(true)}
      src={`${staticMediaStoreBaseURL}/icons/hamburger.svg`}
      style={{ width: 24, height: 24 }}
      width={0}
    />
  ) : (
    <Link href={getPageURL({ pageName: PAGE_NAME.LOGIN.label })}>
      <Button
        {...{
          children: 'Login',
          className:
            'bg-brand-gradient rounded-md py-2 px-6 text-white text-sm md:text-base'
        }}
      />
    </Link>
  );
};

const HeaderMobileOrganism = ({ showWhatsapp = true, showCallIcon = true }) => {
  const { legalNameOfPartnerOrg, mobileNumber } = getAuth() || {};
  const [showNavigationSideBar, setShowNavigationSideBar] = useState(false);

  return (
    <div className='relative h-10 md:h-20'>
      <div className='bg-white shadow-card flex justify-between py-3 md:py-5 px-6 md:px-20 fixed top-0 z-10 w-full '>
        <div className='flex gap-3 md:gap-6'>
          <div className='self-center'>
            <Link href={getPageURL({ pageName: PAGE_NAME.HOME.label })}>
              <Image
                alt='logo'
                className='cursor-pointer'
                height={0}
                priority={true}
                src={`${staticMediaStoreBaseURL}/icons/hafla.svg`}
                style={{
                  width: 58,
                  height: 20
                }}
                width={0}
              />
            </Link>
          </div>
        </div>
        <div className='flex gap-4'>
          {showCallIcon && (
            <Link
              href={`tel:${haflaContactNumber}`}
              id='call-icon'
            >
              <Image
                alt='call-icon'
                className='cursor-pointer'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/call-rounded-icon.svg`}
                style={{ width: 24, height: 24 }}
                width={0}
              />
            </Link>
          )}
          {showWhatsapp && (
            <Image
              alt='whatsapp'
              className='cursor-pointer'
              height={0}
              onClick={() =>
                window.open(
                  getInitiateWhatsappChatLink(),
                  '_blank',
                  'noopener, noreferrer,nofollow'
                )
              }
              src={`${staticMediaStoreBaseURL}/icons/whatsapp-green.svg`}
              style={{ width: 24, height: 24 }}
              width={0}
            />
          )}
          <NavigationMenu {...{ setShowNavigationSideBar }} />
        </div>
      </div>
      {showNavigationSideBar && (
        <NavigationSideBar
          {...{ setShowNavigationSideBar, legalNameOfPartnerOrg, mobileNumber }}
        />
      )}
    </div>
  );
};

export default HeaderMobileOrganism;
