import * as HttpService from '@/services/http.service';
import {
  SEND_OTP_API_URL,
  VERIFY_OTP_WITH_MOBILE_API_URL
} from '@/services/url.service';

const verifyOTPWithMobileNumberAPI = ({ mobileNumber, otp }) =>
  HttpService.postWithoutAuth(VERIFY_OTP_WITH_MOBILE_API_URL, {
    mobileNumber,
    otp
  });

const partnerUserAuthenticationAPI = ({ mobileNumber }) =>
  HttpService.postWithoutAuth(SEND_OTP_API_URL, { mobileNumber });

export const LOGIN_PAGE_JSX_MAP = {
  LOGIN_JSX: 'LoginJSX',
  OTP_INPUT_INTERFACE_JSX: 'OtpInputInterfaceJSX',
  CONTACT_US_MODAL_JSX: 'ContactUsModalJSX'
};

export const partnerUserAuthentication = async ({
  getValues,
  setActiveJSX,
  setOtp,
  setOtpError
}) => {
  const codeId = getValues('codeId');
  const phoneNumber = getValues('phoneNumber');

  const { message, status } = await partnerUserAuthenticationAPI({
    mobileNumber: `${codeId.code}${phoneNumber}`
  });
  if (status) {
    setActiveJSX(LOGIN_PAGE_JSX_MAP.OTP_INPUT_INTERFACE_JSX);
    setOtp(null);
    setOtpError(null);
  } else {
    setOtpError(message);
  }
};

export const verifyOTP = async ({
  getValues,
  login,
  otp,
  query,
  setOtpError
}) => {
  const incompleteOTP = otp.length < 4;
  setOtpError(incompleteOTP ? 'The entered OTP is incorrect' : null);
  if (incompleteOTP) return;
  const currentMobileNumber = `${getValues('codeId').code}${getValues(
    'phoneNumber'
  )}`;
  const { entity, message, status } = await verifyOTPWithMobileNumberAPI({
    mobileNumber: currentMobileNumber,
    otp
  });
  setOtpError(status ? null : message);
  if (status) {
    login({
      userDetails: entity,
      pathName: query.redirectTo || 'home'
    });
  }
};

export const onClickEditButton = ({ setActiveJSX, setOtp, setOtpError }) => {
  setOtpError(null);
  setOtp(null);
  setActiveJSX(LOGIN_PAGE_JSX_MAP.LOGIN_JSX);
};
