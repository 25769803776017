import Image from 'next/image';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  CTA,
  ImageCarousel,
  InputField,
  RichTextEditor,
  Text,
  TextArea,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import {
  BID_ITEM_AVAILABILITY_STATUS,
  BID_ITEM_STATUS,
  BID_STATUS
} from '@/config/bid';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getSerializedRichText } from '@/helpers/editor';
import {
  parseFormatPriceNumberValueFromAPI,
  parsePriceValueForAPI
} from '@/lib/numberStringUtils';
import { useIsMobile } from '@/lib/screenResolution';
import YupValidator from '@/lib/yup-validator';
import { updateBidItem } from '@/services/bid.service';

const itemAvailabilityOptions = [
  {
    buttonName: BID_ITEM_AVAILABILITY_STATUS.NOT_AVAILABLE.label,
    value: BID_ITEM_AVAILABILITY_STATUS.NOT_AVAILABLE.value
  },
  {
    buttonName: BID_ITEM_AVAILABILITY_STATUS.AVAILABLE.label,
    value: BID_ITEM_AVAILABILITY_STATUS.AVAILABLE.value
  }
];

const ItemAvailabilityOption = ({
  buttonName,
  inputDisabled,
  itemAvailabilityStatus,
  setItemAvailabilityStatus,
  setValue,
  value
}) => (
  <Button
    {...{
      children: buttonName,
      className: `flex items-center justify-center text-sm py-2 px-1 text-medium rounded-md ${
        itemAvailabilityStatus === value
          ? 'text-white bg-brand-gradient'
          : 'text-brand bg-lightgray border border-platinum'
      }`,
      disabled: inputDisabled,
      iconHeight: 16,
      iconPosition: 'Left',
      iconUrl:
        itemAvailabilityStatus === value
          ? `${staticMediaStoreBaseURL}/icons/check-white-bg-red-icon.svg`
          : `${staticMediaStoreBaseURL}/icons/circle-icon.svg`,
      iconWidth: 16,
      onClick: () => {
        setItemAvailabilityStatus(value);
        setValue('currentAvailabilityStatus', value);
      }
    }}
  />
);

const getUnitPartnerPriceOffered = ({
  quantityRequested,
  partnerPriceOfferedFromForm,
  itemAvailabilityStatus
}) =>
  itemAvailabilityStatus === BID_ITEM_AVAILABILITY_STATUS.NOT_AVAILABLE.value
    ? null
    : parsePriceValueForAPI(partnerPriceOfferedFromForm / quantityRequested);

const getUpdateBidItemPayloadData = ({
  formData,
  itemAvailabilityStatus,
  quantityRequested
}) => ({
  availabilityStatus: itemAvailabilityStatus,
  partnerNotes: formData.partnerNotes,
  unitPartnerPriceOffered: getUnitPartnerPriceOffered({
    quantityRequested,
    partnerPriceOfferedFromForm: formData.partnerPriceOffered,
    itemAvailabilityStatus
  })
});

const isInputDisabledForBidItem = ({ bidStatus, status }) =>
  bidStatus === BID_STATUS.EXPIRED.value ||
  status !== BID_ITEM_STATUS.PENDING.value;

const CloseIcon = ({ setOpenBidItemDetail }) => (
  <>
    <div
      className='flex justify-end absolute w-full z-2 -mt-2 -ml-10 md:-ml-5'
      onClick={() => setOpenBidItemDetail(null)}
    >
      <Image
        alt='close'
        className='cursor-pointer top-4 absolute'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/close-gray-white-icon.svg`}
        style={{ width: 24, height: 24 }}
        width={0}
      />
    </div>
  </>
);

const BidItemImage = ({ isMobile, mediaList, openImageModal }) => (
  <div
    className={`w-full ${isMobile ? 'h-50' : 'h-96'} relative image-bid-detail`}
  >
    <ImageCarousel
      {...{
        containerStyle: `${isMobile ? 'h-50' : 'h-96'} w-full`,
        excludeImageHeightFromContainer: true,
        images: mediaList,
        isThumbnail: false,
        layout: true,
        objectFit: 'cover',
        openImageModal,
        style: 'rounded-t-xl  '
      }}
    />
  </div>
);

const TenderItemTextDetail = ({ name, notes, quantityRequested }) => (
  <>
    <Text
      {...{
        content: name,
        className: 'text-lg font-semibold'
      }}
    />
    {getSerializedRichText(notes) && (
      <div>
        <Text {...{ content: 'Note:', className: 'text-sm font-medium' }} />
        <RichTextEditor
          {...{
            readOnly: true,
            values: notes
          }}
        />
      </div>
    )}
    <TextKeyValuePair
      {...{
        className: 'flex gap-2 my-3',
        label: 'Requested Quantity: ',
        labelClass: 'text-sm font-medium',
        spaceTop: '',
        value: quantityRequested,
        valueClassName: 'text-sm font-light'
      }}
    />
  </>
);

const BidItemQuotationDetail = ({
  bidStatus,
  errors,
  handleSubmit,
  inputDisabled,
  itemAvailabilityStatus,
  onSubmit,
  partnerNotes,
  priceRequestedFormattedString,
  register
}) =>
  itemAvailabilityStatus && (
    <div className='mt-4'>
      {itemAvailabilityStatus ===
        BID_ITEM_AVAILABILITY_STATUS.AVAILABLE.value && (
        <div className='flex gap-6 text-sm font-medium'>
          <div className='flex flex-col gap-2 w-1/2'>
            <InputField
              {...{
                label: 'Total Ask Price:',
                labelClass: 'mb-2 flex',
                className: 'rounded-md',
                defaultValue: priceRequestedFormattedString,
                disabled: true
              }}
            />
          </div>
          <div className='w-1/2'>
            <InputField
              {...{
                type: 'number',
                register: register('partnerPriceOffered'),
                label: 'Total Offer Price:',
                labelClass: 'mb-2 flex',
                className: 'rounded-md',
                disabled: inputDisabled,
                errors,
                dbName: 'partnerPriceOffered'
              }}
            />
          </div>
        </div>
      )}
      <Text
        {...{
          content: '*The above prices are excluding VAT',
          className: 'text-xs font-light mt-2'
        }}
      />
      <div className='text-sm font-medium mt-4'>
        <Text
          {...{
            content: 'Comments:',
            className: `mb-2 ${bidStatus} bid-item-detail`
          }}
        />
        <TextArea
          {...{
            register: register('partnerNotes'),
            defaultValue: partnerNotes,
            disabled: inputDisabled
          }}
        />
      </div>
      <CTA
        {...{
          className: 'font-medium px-3 mt-4',
          buttonColor: `${
            bidStatus === BID_STATUS.EXPIRED.value
              ? 'bg-dim-gray'
              : 'bg-brand-gradient'
          } w-full text-white h-11 px-6 md:py-4 text-sm`,
          onClick: handleSubmit(onSubmit),
          children: 'Save',
          disabled: inputDisabled
        }}
      />
    </div>
  );

const BidItemDetail = ({
  bidId,
  bidStatus,
  openBidItemDetail,
  partnerUser,
  referenceId,
  setBidDetails,
  setDeliveryChargeOffered,
  setDeliveryMode,
  setLoading,
  setOpenBidItemDetail,
  setShowToast
}) => {
  const {
    availabilityStatus,
    id: bidItemId,
    openImageModal,
    partnerNotes,
    status,
    tenderItem: {
      name,
      notes,
      quantityRequested,
      unitPriceRequested,
      mediaList
    },
    unitPartnerPriceOffered
  } = openBidItemDetail;

  const [isMobile] = useIsMobile();
  const [itemAvailabilityStatus, setItemAvailabilityStatus] =
    useState(availabilityStatus);
  const yupResolver = YupValidator(
    yup.object().shape({
      partnerPriceOffered: yup
        .number()
        .typeError('Amount must be a number')
        .when(
          'currentAvailabilityStatus',
          (currentAvailabilityStatus, schema) => {
            if (
              currentAvailabilityStatus.includes(
                BID_ITEM_AVAILABILITY_STATUS.AVAILABLE.value
              )
            ) {
              return schema.min(1, 'Minimum amount should be > 0');
            }
            return schema;
          }
        )
        .nullable()
    })
  );

  const priceRequested = unitPriceRequested
    ? unitPriceRequested * quantityRequested
    : 0;
  const priceRequestedFormattedString = unitPriceRequested
    ? parseFormatPriceNumberValueFromAPI(priceRequested)
    : '-';

  const partnerPriceOffered = unitPartnerPriceOffered
    ? unitPartnerPriceOffered * quantityRequested
    : 0;
  const partnerPriceOfferedFormattedNumber =
    (unitPartnerPriceOffered
      ? parseFormatPriceNumberValueFromAPI(partnerPriceOffered)
      : Number(priceRequestedFormattedString)) || 0;

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      availabilityStatus,
      currentAvailabilityStatus: availabilityStatus,
      partnerPriceOffered: partnerPriceOfferedFormattedNumber,
      partnerNotes
    }
  });

  const inputDisabled = isInputDisabledForBidItem({ bidStatus, status });

  const onSubmit = async (formData) => {
    const { currentAvailabilityStatus, ...payload } = formData;
    await updateBidItem({
      bidId,
      bidItemId,
      data: getUpdateBidItemPayloadData({
        formData: payload,
        itemAvailabilityStatus: getValues('currentAvailabilityStatus'),
        quantityRequested
      }),
      partnerUser,
      referenceId,
      setBidDetails,
      setDeliveryChargeOffered,
      setDeliveryMode,
      setLoading,
      setOpenBidItemDetail,
      setShowToast
    });
  };

  const bidbidStatusBasedClassName =
    bidStatus === BID_STATUS.EXPIRED.value ? 'expired-bid' : '';
  return (
    <div
      className={`relative ${bidbidStatusBasedClassName} bit-item-detail mb-4`}
    >
      <div className='flex flex-col gap-3 mx-4 md:mx-0 shadow pb-5 rounded-lg'>
        <CloseIcon {...{ setOpenBidItemDetail }} />
        <BidItemImage {...{ isMobile, mediaList, openImageModal }} />
        <div className='px-4 md:px-8'>
          <TenderItemTextDetail {...{ name, notes, quantityRequested }} />
          <div className='flex gap-6'>
            {itemAvailabilityOptions.map(({ buttonName, value }, index) => (
              <ItemAvailabilityOption
                key={`${buttonName}_${index}`}
                {...{
                  buttonName,
                  index,
                  inputDisabled,
                  itemAvailabilityStatus,
                  setItemAvailabilityStatus,
                  setValue,
                  value
                }}
              />
            ))}
          </div>
          <BidItemQuotationDetail
            {...{
              bidStatus,
              errors,
              handleSubmit,
              inputDisabled,
              itemAvailabilityStatus,
              onSubmit,
              partnerNotes,
              priceRequestedFormattedString,
              register
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BidItemDetail;
