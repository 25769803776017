import Image from 'next/image';

import { Text } from '@/components/atomic/atoms';
import { DEFAULT_TOAST_CONFIG, staticMediaStoreBaseURL } from '@/config/common';

const Icon = ({ iconSrc }) => (
  <div className='self-center w-4 h-4 flex'>
    <Image
      alt='ToasterIcon'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${iconSrc}`}
      style={{ width: 16, height: 16 }}
      width={0}
    />
  </div>
);

const Toaster = ({
  iconSrc,
  message,
  position = 'bottom-10',
  setShowToast,
  showIcon
}) => {
  setTimeout(() => setShowToast(DEFAULT_TOAST_CONFIG), 5000);
  return (
    <div
      className={`bg-black text-white text-sm font-medium flex gap-3 justify-center py-3
      rounded-lg px-6 opacity-80 fixed ${position} w-4/5 md:w-auto toaster z-50`}
    >
      {showIcon && <Icon {...{ iconSrc }} />}
      <Text
        content={message}
        className='self-center flex flex-1'
      />
    </div>
  );
};

export default Toaster;
