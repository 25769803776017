import Image from 'next/image';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import { CloseIcon, CTA, Text, TextArea } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import YupValidator from '@/lib/yup-validator';
import { postCallBackRequestForBid } from '@/services/bid.service';

const BidRequestCallBack = ({
  bidId,
  setShowRequestCallbackModal,
  setShowToast
}) => {
  const yupResolver = YupValidator(
    yup.object().shape({
      messageFromPartner: yup.string().required('Please describe')
    })
  );
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver
  });

  return (
    <div className='bid-request-modal fixed z-50'>
      <div className='flex gap-5 justify-center h-screen mx-auto text-sm font-medium'>
        <div className='self-start w-80 md:w-112 z-20 bg-white rounded-lg px-6 md:px-8 pt-3 pb-8'>
          <div>
            <CloseIcon
              {...{
                className: 'flex justify-end',
                iconClassName: 'cursor-pointer',
                onClick: () => setShowRequestCallbackModal(false)
              }}
            />
            <div className='flex flex-col gap-5 text-center'>
              <Text
                {...{
                  className: 'text-lg font-medium',
                  content: 'Request Callback'
                }}
              />
              <div className='mx-auto'>
                <Image
                  alt='bid-call-back'
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/bid-call-back.svg`}
                  style={{ width: 64, height: 64 }}
                  width={0}
                />
              </div>
              <Text
                {...{
                  className: 'text-sm font-medium',
                  content:
                    'Please Mention your request here, will get back to you soon!'
                }}
              />
              <TextArea
                {...{
                  dbName: 'messageFromPartner',
                  errors,
                  placeholder: 'Type here',
                  register: register('messageFromPartner')
                }}
              />
              <CTA
                {...{
                  buttonColor:
                    'bg-brand-gradient text-white h-11 px-6 md:py-4 text-sm',
                  children: 'Confirm',
                  className: 'font-medium px-3',
                  onClick: handleSubmit(({ messageFromPartner }) =>
                    postCallBackRequestForBid({
                      bidId,
                      messageFromPartner,
                      setShowRequestCallbackModal,
                      setShowToast
                    })
                  )
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BidRequestCallbackModal = ({
  bidId,
  handleCloseRequestCallbackModal,
  setShowRequestCallbackModal,
  setShowToast,
  showRequestCallbackModal,
  showToast
}) => (
  <div className='mx-4'>
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={showRequestCallbackModal}
      size='lg'
      toggle={handleCloseRequestCallbackModal}
    >
      <ModalBody className='py-5 mx-5'>
        <BidRequestCallBack
          {...{
            bidId,
            setShowRequestCallbackModal,
            setShowToast,
            showToast
          }}
        />
      </ModalBody>
    </Modal>
  </div>
);

export default BidRequestCallbackModal;
