import * as HttpService from 'services/http.service';
import { getPageURL, PAGE_NAME } from 'services/partnerPortal.service';
import {
  CONTACT_US_FORM_API_URL,
  GUEST_CONTACT_US_API_URL
} from 'services/url.service';

const contactUsFormAPI = ({ data }) =>
  HttpService.postWithAuth(CONTACT_US_FORM_API_URL(), data);

const guestContactUsAPI = (data) =>
  HttpService.postWithoutAuth(GUEST_CONTACT_US_API_URL, data);

export const contactHaflaForm = async ({ data, setLoading, setShowToast }) => {
  setLoading(true);
  try {
    const { status } = await contactUsFormAPI({ data });
    setShowToast({
      message: status
        ? 'Message sent successfully'
        : 'Message not sent, something went wrong',
      show: true,
      successToast: status
    });
  } catch (error) {
    setShowToast({ show: true, successToast: false, message: error.message });
  } finally {
    setLoading(false);
  }
};

export const guestContactUs = async ({
  getValues,
  message,
  name,
  router,
  setLoading,
  setShowOverlayer,
  setShowToast
}) => {
  setLoading(true);
  const codeId = getValues('codeId');
  const mobileNumber = getValues('mobileNumber');
  try {
    const { status } = await guestContactUsAPI({
      context: 'LOGIN',
      message,
      mobile: `${codeId.code}${mobileNumber}`,
      name
    });
    setShowOverlayer(status);
    setShowToast({
      message: status
        ? 'Your request has been sent successfully, we will get back to you soon!'
        : 'Failed to Submit! Try again',
      position: 'top-5',
      show: true,
      successToast: status
    });
    if (status) {
      setTimeout(
        () =>
          router.push(getPageURL({ pageName: PAGE_NAME.SPLASH_SCREEN.label })),
        5000
      );
    }
  } catch (error) {
    setShowToast({
      message: error.message || 'Error! Failed to submit ',
      position: 'top-5',
      show: false,
      successToast: false
    });
  } finally {
    setLoading(false);
  }
};
