import Image from 'next/image';

import { Button, ErrorMessageWithIcon, Text } from '@/components/atomic/atoms';
import { OtpInputField } from '@/components/atomic/molecules';
import { staticMediaStoreBaseURL } from '@/config/common';
import { LOGIN_PAGE_JSX_MAP } from '@/services/login.service';

const ResendOtp = ({
  getValues,
  onClickResendOTP,
  setActiveJSX,
  setOtp,
  setOtpError
}) => (
  <div className='font-medium px-5 pt-2 mb-10 text-xs md:text-sm'>
    <Text
      {...{
        content: 'Didn’t receive the OTP? ',
        HtmlTag: 'span'
      }}
    />
    <Text
      {...{
        className: 'text-denim-blue cursor-pointer',
        content: '  Click here to re-send',
        HtmlTag: 'span',
        onClick: () =>
          onClickResendOTP({
            getValues,
            setActiveJSX,
            setOtp,
            setOtpError
          })
      }}
    />
  </div>
);

const OtpInputInterfaceOrganism = ({
  getValues,
  login,
  onClickEditButton,
  onClickResendOTP,
  verifyOTP,
  otp,
  otpError,
  query,
  setActiveJSX,
  setOtp,
  setOtpError
}) => (
  <div className='bg-brand-gradient rounded-t-4xl md:w-43.5 fixed bottom-0 mx-auto left-0 right-0 text-white'>
    <div className='flex flex-col gap-4 font-medium pt-10 px-5'>
      <div>
        <Text
          {...{
            className: 'text-base font-medium float-left',
            content: `Enter the 4 digit OTP sent via SMS to`,
            HtmlTag: 'span'
          }}
        />
        <Text
          {...{
            className: 'text-base font-medium float-left md:px-2',
            content: `${getValues('codeId')?.code}${getValues('phoneNumber')}`,
            HtmlTag: 'span'
          }}
        />
        <Image
          alt='edit'
          className='cursor-pointer float-left'
          height={0}
          onClick={() =>
            onClickEditButton({ setActiveJSX, setOtp, setOtpError })
          }
          src={`${staticMediaStoreBaseURL}/icons/edit-white-icon.svg`}
          style={{ width: 18, height: 18 }}
          width={0}
        />
      </div>
      <div className='flex justify-center'>
        <OtpInputField
          {...{
            className: 'border border-1 rounded-lg',
            disabledStyle: { backgroundColor: '#eee' },
            focusStyle: { border: '2px solid white' },
            getValues,
            hasErrored: false,
            inputStyle: 'inputStyle !w-12 h-12 rounded-md phone-code-input',
            isDisabled: false,
            isInputNum: true,
            login,
            numInputs: 4,
            verifyOTP,
            otp,
            placeholder: '0000',
            query,
            setOtp,
            setOtpError,
            shouldAutoFocus: false,
            value: otp
          }}
        />
      </div>
      {otpError && (
        <ErrorMessageWithIcon
          {...{ errorMessage: otpError, errorContainerClass: 'justify-center' }}
        />
      )}
      <Button
        {...{
          children: 'Verify',
          className: 'bg-white text-brand rounded-md py-3',
          onClick: () =>
            verifyOTP({
              getValues,
              login,
              otp,
              query,
              setOtpError
            })
        }}
      />
    </div>
    <ResendOtp
      {...{
        getValues,
        onClickResendOTP,
        setActiveJSX,
        setOtp,
        setOtpError
      }}
    />
    <div className='border-t border-base-white py-5 mx-6'>
      <Button
        {...{
          children: 'Contact Us',
          className:
            'bg-transprent border border-white text-white rounded-md py-3 font-medium mt-3',
          iconPosition: 'Left',
          iconUrl: `${staticMediaStoreBaseURL}/icons/call-white.svg`,
          iconWidth: 20,
          iconHeight: 20,
          onClick: () => setActiveJSX(LOGIN_PAGE_JSX_MAP.CONTACT_US_MODAL_JSX)
        }}
      />
      <Text
        {...{
          content: 'In case you need any help!',
          className: 'text-xs md:text-sm font-medium mt-2 text-base-white'
        }}
      />
    </div>
  </div>
);

export default OtpInputInterfaceOrganism;
